import {FaqElement} from '~/service/jsonLd/FAQ/useFAQ';

export function useBorrowersGuide() {
  const guideItems: FaqElement[] = [
    {
      question: 'Czym jest porównywarka kredytów hipotecznych?',
      answer: 'Przy zaciąganiu kredytu hipotecznego, który jest zaliczany do grona długoterminowych zobowiązań finansowych, zabezpieczonych w formie wpisu hipoteki do księgi wieczystej nieruchomości na rzecz banku, najlepiej porównać ze sobą co najmniej kilka ofert. Nie należy korzystać od razu z pierwszej lepszej propozycji, ponieważ każdy bank może prowadzić odmienną politykę i udzielać zobowiązań na nieco innych warunkach, w tym również cenowych.' +
          '<br>' +
          'Dlatego przy wyborze jednej oferty finansowania nabycia nieruchomości warto zestawić ze sobą koszty kredytu hipotecznego, w tym wysokość oprocentowania, RRSO (Rzeczywistej Rocznej Stopy Oprocentowania), prowizji za udzielenie zobowiązania. Zgromadzenie tych wszystkich informacji może trochę potrwać.' +
          '<br>' +
          'Da się jednak znacznie ułatwić sobie to zadanie dzięki porównywarce kredytów hipotecznych, która jest narzędziem służącym do sprawdzenia ofert pod kątem kosztów, okresu spłaty czy wysokości zobowiązania, jakie może zostać udzielone klientowi.' +
          '<br>' +
          'W jednym miejscu są zgromadzone aktualne oferty instytucji, które możesz dowolnie sortować i filtrować zgodnie ze swoimi oczekiwaniami.',
    },
    {
      question: 'Co to jest kalkulator kredytowy?',
      answer: 'Szczegółowy kalkulator kredytu hipotecznego jest przydatnym narzędziem dla potencjalnych kredytobiorców. Podobnie jak w przypadku rankingu kredytów hipotecznych także on dostarcza cennych informacji dotyczących wyboru właściwej oferty finansowania zewnętrznego inwestycji polegającej na zakupie własnego mieszkania. Kalkulator kredytowy pozwala Ci w warunkach domowych sprawdzić koszty kredytu gotówkowego albo hipotecznego oraz porównać wysokość rat przy dowolnie wybranych parametrach.'
    },
    {
      question: 'Jak korzystać z kalkulatora kredytu hipotecznego?',
      answer: 'Obsługa kalkulatora kredytu hipotecznego jest prosta, nie trzeba mieć szerokiej wiedzy w zakresie obsługi stron WWW, by móc się nim wygodnie posługiwać. To formularz elektroniczny, który z reguły wymaga podania najważniejszych parametrów kredytu hipotecznego, który chcesz zaciągnąć. Wśród nich znajdą się takie dane jak:<br>' +
          '• kwota kredytu hipotecznego w złotówkach,<br>' +
          '• okres spłaty,<br>' +
          '• wysokość oprocentowania nominalnego w skali roku,<br>' +
          '• wysokość prowizji kredytowej za przystąpienie do zobowiązania,<br>' +
          '• wartość nieruchomości,<br>' +
          '• typ spłacanych rat – malejące lub równe (stałe) w okresie kredytowania.<br>' +
          'Na podstawie podanych parametrów kalkulator kredytowy online w kilka sekund oblicza prawdopodobną, choć jedynie orientacyjną wysokość raty kapitałowo-odsetkowej spłacanej miesięcznie albo też wskazuje harmonogram spłaty rat (w przypadku rat malejących). Może też pokazywać od razu, ile wynosi RRSO kredytu hipotecznego o wskazanych kryteriach udzielenia czy wysokość całkowitych kosztów kredytowania lub całkowitej kwoty do spłaty.<br>' +
          'Możesz wówczas odnieść jej wysokość do swoich dochodów i indywidualnej zdolności. Słowem, sam odpowiesz sobie na pytanie, czy stać Cię na zaciągnięcie kredytu hipotecznego w danej wysokości, na konkretny czas z podanym poziomem oprocentowania i pozostałych kosztów. Kalkulator kredytowy jest dostępny całą dobę, więc możesz z niego korzystać, kiedy chcesz i uzupełniać go wielokrotnie dobierając różne parametry.'
    },
    {
      question: 'Co to jest zdolność kredytowa?',
      answer: 'Jednym z podstawowych warunków, jakie banki stawiają potencjalnym klientom, wnioskującym o kredyt hipoteczny, jest posiadanie odpowiednio wysokiej zdolności kredytowej. Definiowana jest ona zwykle w ten sposób, że jest to zdolność danej osoby do spłaty zobowiązania kredytowego wraz z odsetkami i innymi naliczonymi w związku z nim opłatami.<br>' +
          'Szacowanie zdolności jest integralnym elementem procesu weryfikacji kredytowej. Bank jest zobowiązany przez rekomendacje wydawane przez Komisję Nadzoru Finansowego do tego, by sprawdzać klienta, w tym jego zdolność do spłaty zobowiązań finansowych.<br>' +
          'Każdy bank może mieć nieco inne wytyczne dotyczące wyliczania zdolności kredytowej. W najprostszym wariancie od kwoty dochodów miesięcznych kredytobiorcy są odejmowane miesięczne koszty kształtowane przez:<br>' +
          '• czynsz za mieszkanie,<br>' +
          '• opłaty za media,<br>' +
          '• stałe wydatki, jak utrzymanie siebie czy rodziny, utrzymanie samochodu, składki na ubezpieczenia, alimenty i tym podobne,<br>' +
          '• raty dotychczas spłacanych kredytów, pożyczek, kart kredytowych, ciążących na kliencie limitów w koncie osobistym.<br>' +
          'Banki po odjęciu od dochodów klienta ponoszonych przez niego kosztów otrzymują sumę, którą może on wykorzystać na spłatę raty kapitałowo-odsetkowej. Niemniej kredytobiorca nie może zostać bez pieniędzy, dlatego musi mu pozostać jakakolwiek kwota do dyspozycji. Jej wysokość jest ustalana indywidualnie w bankach.<br>' +
          'Na wysokość zdolności kredytowej wpływ mają:<br>' +
          '• wysokość dochodów i miesięcznych kosztów,<br>' +
          '• wysokość zaciągniętych dotychczas kredytów i pożyczek,<br>' +
          '• wykonywany zawód,<br>' +
          '• źródło dochodów,<br>' +
          '• wiek i płeć,<br>' +
          '• liczba osób na utrzymaniu,<br>' +
          '• historia kredytowania w BIK.<br>' +
          'Każda instytucja udzielająca kredytu hipotecznego może posługiwać się innym algorytmem przy wyliczaniu zdolności.'
    },
    {
      question: 'Co może być wkładem własnym?',
      answer: 'Przy ubieganiu się o kredyt hipoteczny klient musi przedstawić posiadany wkład własny, nazywany również kapitałem początkowym. Wynika to z faktu, że od 2014 roku Komisja Nadzoru Finansowego narzuciła bankom wymóg żądania od kredytobiorców wkładu w określonej wysokości. Miało to miejsce na mocy wydanej Rekomendacji S KNF. W 2014 roku wymagany wkład własny wynosił 5 proc., ale z roku na rok rósł, aż do 2017 roku, gdy osiągnął pułap 20 proc. wartości kupowanej na kredyt nieruchomości, stanowiącej zabezpieczenie spłaty.<br>' +
          'Jeśli więc zaciągasz kredyt na dom lub mieszkanie, które warte jest 350 tys. zł, to powinieneś dysponować przynajmniej 70 000 zł kapitału początkowego. W wielu bankach połowa wymaganego wkładu własnego może być zastąpiona przez odpowiednie ubezpieczenie. Nazywane jest ono ubezpieczeniem niskiego wkładu własnego lub braku wkładu.<br>' +
          '<br>' +
          'Wielu kredytobiorców nie zdaje sobie sprawy z tego, że w roli kapitału początkowego sprawdzą się nie tylko pieniądze w gotówce czy na koncie osobistym. Wkładem własnym przy kredycie hipotecznym akceptowalnym w bankach mogą być ponadto:' +
          '<br>' +
          '• wartość posiadanej na własność nieruchomości, np. działki budowlanej,' +
          '<br>' +
          '• wartość papierów wartościowych,' +
          '<br>' +
          '• oszczędności na lokatach terminowych i kontach oszczędnościowych,' +
          '<br>' +
          '• pieniądze zgromadzone w Pracowniczych Planach Kapitałowych, Indywidualnych Kontach Zabezpieczenia Emerytalnego, Indywidualnych Kontach Emerytalnych,' +
          '<br>' +
          '• książeczki mieszkaniowe wraz z premią gwarancyjną,' +
          '<br>' +
          '• zadatek wpłacony przy okazji podpisywania umowy przedwstępnej nabycia nieruchomości,' +
          '<br>' +
          '• darowizna od rodziny itp.<br>' +
          'Dlatego fakt, że nie masz na koncie kilkudziesięciu tysięcy złotych, które mógłbyś przedstawić do kredytu hipotecznego przy wnioskowaniu o takie finansowanie, nie powoduje jeszcze, że nie masz szans na pozytywną decyzję.'
    },
    {
      question: 'Dodatkowe zabezpieczenia kredytu hipotecznego',
      answer: 'Podstawowym zabezpieczeniem spłaty kredytu hipotecznego jest wpis hipoteki na rzecz banku do księgi wieczystej nieruchomości klienta. Niemniej nie musi być to jedyne, jakiego będzie wymagał od Ciebie bank. Zabezpieczenia dzielą się na osobiste i rzeczowe. Te pierwsze charakteryzują się odpowiedzialnością osobistą za dane zobowiązanie. Natomiast rzeczowe ograniczają odpowiedzialność osoby, która daje takie zabezpieczenie, do wskazanych poszczególnych składników majątku.' +
          '<br>' +
          'W roli dodatkowego zabezpieczenia spłaty kredytu hipotecznego mogą się sprawdzić:' +
          '<br>' +
          '• Poręczenie osoby trzeciej (żyranta) – według prawa cywilnego, w ramach którego poręczyciel zobowiązuje się spłacić kredyt hipoteczny, jeśli kredytobiorca nie wywiąże się ze spłaty rat.' +
          '<br>' +
          '• Poręczenie wekslowe – zobowiązuje poręczyciela na równi z wystawcą weksla do spłaty kredytu hipotecznego w terminach płatności.' +
          '<br>' +
          '• Weksel – dokument, również w formie in blanco, który określa sumę wekslową i termin płatności lub pozostawia miejsce na wpisanie takich informacji.' +
          '<br>' +
          '• Gwarancja bankowa – zobowiązanym do zapłaty określonej sumy pieniężnej na rzecz klienta jest bank wydający gwarancję, który musi spłacić nieuiszczone raty wraz z należnymi odsetkami i innymi opłatami.' +
          '<br>' +
          '• Przystąpienie do długu – do dotychczasowego kredytobiorcy dołącza inna osoba, występująca jako dłużnik solidarny wobec banku.' +
          '<br>' +
          '• Przejęcie długu – osoba trzecia wchodzi w rolę głównego kredytobiorcy.' +
          '<br>' +
          '• Cesja wierzytelności – to umowa pomiędzy kredytobiorcą a kredytodawcą, na mocy której klient przenosi na bank swoje prawa do otrzymania konkretnej sumy za sprzedane towary bądź usługi.' +
          '<br>' +
          '• Zastaw ogólny – ustanawiany na rzeczy ruchomej, która jest obciążona prawem pozwalającym wierzycielowi dochodzić zaspokojenia swoich roszczeń z tej rzeczy, bez względu na to, czyją własnością się stała.' +
          '<br>' +
          '• Zastaw rejestrowy – stosowany przy kredytach hipotecznych lub pożyczkach, obejmuje zwykle zastaw na ruchomości, takiej jak samochód, który w dalszym ciągu może być wykorzystywany przez dłużnika.' +
          '<br>' +
          '• Kaucja – kredytobiorca składa bankowi zabezpieczenie w formie pieniężnej lub w formie papierów wartościowych na okaziciela, które zabezpieczają roszczenie na wypadek niewywiązania się z umowy kredytowej.' +
          '<br>' +
          '• Blokada środków na rachunku bankowym.' +
          '<br>' +
          '• Przewłaszczenie na zabezpieczenie – polega na przeniesieniu przez kredytobiorcę prawa własności swojej rzeczy na bank.' +
          '<br>' +
          '• Kaucja bankowa – polega na przeniesieniu określonej kwoty pieniężnej na rzecz kredytodawcy.' +
          '<br>' +
          '• Ubezpieczenie kredytu hipotecznego – o tym szerzej w kolejnym punkcie.'
    },
    {
      question: 'Ubezpieczenie kredytu hipotecznego',
      answer: 'Jednym z rodzajów rzeczowych zabezpieczeń kredytu hipotecznego są ubezpieczenia. Wyróżnić można przy tym:' +
          '<br>' +
          '• Ubezpieczenie pomostowe – ustanawiane do czasu dokonania wpisu hipoteki na rzecz banku do księgi wieczystej nieruchomości, a po dokonaniu takiej czynności klient przestaje płacić na nie składki, a bank zwraca mu pobrana opłatę.' +
          '<br>' +
          '• Ubezpieczenie niskiego wkładu własnego lub braku wkładu własnego – wykupowane, jeśli nie masz dostatecznej kwoty czy wartości tytułem kapitału początkowego. Połowę wymaganego w bankach wkładu, który zwykle wynosi 20 proc. wartości nieruchomości, można zastąpić takim ubezpieczeniem, na które składkę płaci się do czasu uiszczenia do banku rat kapitałowych na kwotę odpowiadającą brakującemu wkładowi.' +
          '<br>' +
          '• Ubezpieczenie na życie kredytobiorcy – w razie śmierci klienta ubezpieczyciel pokrywa spłatę dalszej części kredytu hipotecznego.' +
          '<br>' +
          '• Ubezpieczenie od utraty pracy – uruchamiane jest, jeśli w okresie kredytowania stracisz pracę, ale nie z własnej winy – nie możesz zostać zwolniony np. dyscyplinarnie czy samemu rzucić pracy. Ubezpieczyciel przez ustalony czas będzie spłacał w Twoim imieniu raty kapitałowo-odsetkowe do czasu podjęcia pracy.' +
          '<br>' +
          '• Ubezpieczenie nieruchomości od ognia i innych zdarzeń losowych – jeśli zdarzyłby się pożar w nieruchomości stanowiącej zabezpieczenie hipoteczne kredytu, to odszkodowanie z takiego ubezpieczenia w pierwszej kolejności trafia do banku, na pokrycie pozostałego do spłaty kredytu, a jeśli będzie to wyższa kwota, pozostała część trafi do Ciebie.'
    },
    {
      question: 'Jakie dokumenty są wymagane przy składaniu wniosku o kredyt hipoteczny?',
      answer: 'Podstawą ubiegania się w banku o kredyt hipoteczny jest poprawnie wypełniony wniosek kredytowy, najczęściej na formularzu, w którym wpisujesz swoje dane osobowe, numer dokumentów tożsamości, a także kwotę wnioskowanego zobowiązania wraz z pożądanym okresem spłaty. Deklarujesz, jakie masz źródło dochodów i jakie koszty miesięcznie ponosisz, a ponadto składasz na ręce pracownika banku zgodę na skontrolowanie Twojej historii w BIK przed wydaniem decyzji.' +
          '<br>' +
          'Do wniosku o kredyt hipoteczny powinieneś dołączyć odpowiednie, wymagane przez bank dokumenty:' +
          '<br>' +
          '• Osobowe – głównie dowód osobisty i drugi dokument ze zdjęciem, który ponad wszelką wątpliwość potwierdzi, że jesteś tym, za kogo się podajesz.' +
          '<br>' +
          '• Dotyczące Twojej sytuacji finansowej – w tym zaświadczenie o wysokości uzyskiwanych dochodów od pracodawcy, dokumenty księgowe potwierdzające zarobki otrzymywane w związku z prowadzoną działalnością gospodarczą, odcinek renty lub emerytury, rejestr przychodów z najmu prywatnego, potwierdzenie złożenia PIT z poprzedniego roku podatkowego itp.' +
          '<br>' +
          '• Dotyczące kupowanej nieruchomości – lista takich dokumentów jest uzależniona od typu nieruchomości, na jaką zaciągasz kredyt hipoteczny. Inne dokumenty będą potrzebne przy finansowaniu budowy domu, inne przy kupowaniu mieszkania z rynku pierwotnego, a jeszcze inne, jeśli nabywasz lokal używany. W tym ostatnim przypadku będziesz musiał dołączyć do wniosku umowę przedwstępną nabycia nieruchomości, operat szacunkowy wykonany przez rzeczoznawcę majątkowego, odpis z księgi wieczystej, dokument potwierdzający prawo własności zbywcy do nieruchomości, dokumenty dotyczące niezalegania z opłatami za czynsz i nie tylko.'
    },
    {
      question: 'Na co zwrócić uwagę przy wyborze oferty kredytu hipotecznego?',
      answer: 'Wybór oferty kredytu hipotecznego rzutuje na Twoje finanse zwykle przez wiele lat, dlatego powinieneś poświęcić wystarczająco dużo czasu, by dokonać jak najlepszego wyboru w danym momencie. Zwróć uwagę na takie kwestie, jak:' +
          '<br>' +
          '• Minimalna i maksymalna kwota kredytu hipotecznego – miej przy tym świadomość, że maksymalna kwota, jaką udzieli Ci bank w ramach kredytowania zakupu nieruchomości, jest uzależniona od Twojej zdolności.' +
          '<br>' +
          '• Wysokość prowizji bankowej naliczanej z tytułu przystąpienia do zobowiązania.' +
          '<br>' +
          '• Wysokość marży bankowej, która ma znaczenie dla wysokości oprocentowania.' +
          '<br>' +
          '• Wysokość oprocentowania nominalnego i to, na jakiej stawce się opiera – czy jest to stawka np. WIBOR(R) 3M czy 6M.' +
          '<br>' +
          '• Wysokość RRSO – Rzeczywistej Rocznej Stopy Oprocentowania.' +
          '<br>' +
          '• Wysokość całkowitych kosztów kredytowania.' +
          '<br>' +
          '• Typ oprocentowania – stałe czy zmienne.' +
          '<br>' +
          '• Wysokość wymaganego wkładu własnego – może być to standardowe 20 proc. wkładu lub mniej albo więcej.' +
          '<br>' +
          '• Wymagania banku dotyczące zdolności kredytowej, m.in. jakie źródła pozyskiwania dochodów będą akceptowalne przy wyliczaniu zdolności kredytowej.' +
          '<br>' +
          '• Warunki umowy kredytowej.' +
          '<br>' +
          '• Maksymalny okres spłaty kredytu hipotecznego.' +
          '<br>' +
          '• Wymagane dodatkowe zabezpieczenia spłaty zobowiązania.' +
          '<br>' +
          '• Tryb spłaty rat – w systemie rat malejących w okresie kredytowania lub rat stałych.' +
          '<br>' +
          '• Możliwość skorzystania z karencji w spłacie kredytu hipotecznego i warunki, na jakich są udzielane tego rodzaju wakacje kredytowe.' +
          '<br>' +
          '• Czy nadpłata kredytu hipotecznego wymaga dodatkowych kosztów.' +
          '<br>' +
          '• Jaki jest koszt za wcześniejszą spłatę kredytu hipotecznego.' +
          '<br>' +
          '' +
          '<br>' +
          'Na podstawie analizy wskazanych parametrów, kalkulator rat kredytu hipotecznego szybko pokaże Ci najlepsze oferty. W ten sposób będziesz w stanie wybrać spośród kilku różnych banków najlepszy kredyt hipoteczny.'
    },
    {
      question: 'Oprocentowanie kredytu hipotecznego – stałe czy zmienne?',
      answer: 'Banki są zobowiązane do tego, by zaoferować Ci kredyt hipoteczny nie tylko ze zmienną stopą oprocentowania, ale i ze stopą stałą, choć tylko na 5 kolejnych lat. Później wysokość stopy oprocentowania jest dostosowywana do tego, jakie warunki rynkowe panują w branży bankowej. Obecnie są instytucje, oferujące kredyt nawet na 10 lat.' +
          '<br>' +
          'Kredyty hipoteczne ze zmiennym oprocentowaniem stanowią największy udział dotychczas udzielonych kredytów hipotecznych przez polskie banki. Oprocentowanie składa się ze stałej marży oraz stawki rynkowej WIBOR(R) 3M (3-miesięcznej) lub 6M (6-miesięcznej). Jego wysokość jest uzależniona od stawki WIBOR(R) w odpowiednim okresie, co oznacza, że będzie ona zmieniała się co 3 albo 6 miesięcy. Z kolei na sam WIBOR(R) wpływ ma wysokość głównych stóp procentowych w Polsce. Jeśli rosną, to także oprocentowanie kredytu hipotecznego będzie wzrastało.' +
          '<br>' +
          'Warto nadmienić, że od 2024 r. zamiast WIBOR(R) będzie funkcjonował WIRON. Natomiast już od 2023 r. banki stopniowo będą wprowadzać nowy wskaźnik.' +
          '<br>' +
          'Kredyt hipoteczny ze stałą stawką procentową ma od razu z góry znane oprocentowanie obowiązujące przez np. 5 kolejnych lat. Po upływie tego czasu bank rewiduje wysokość tego oprocentowania, które znów może być gwarantowane na kolejne lata. Stałe to dobry sposób na zlikwidowanie ryzyka zmiany stóp procentowych, ale może być ono nieco wyższe od oprocentowania według zmiennej stopy procentowej.' +
          '<br>' +
          'Zastanawiasz się, jak obliczyć oprocentowanie kredytu hipotecznego?<br> W tym celu możesz zastosować prosty wzór: O = K*r*n/Y. Poszczególne symbole wyrażają:' +
          '<br>' +
          'O – wartość odsetek miesięcznie,' +
          '<br>' +
          'K – suma kapitału pozostałego do spłaty,' +
          '<br>' +
          'r – oprocentowanie kredytu w skali roku,' +
          '<br>' +
          'n – liczba dni w miesiącu, dla którego obliczane są odsetki,' +
          '<br>' +
          'Y – liczba dni w roku.' +
          '<br>' +
          'Na szczęście nie musisz tego samodzielnie liczyć. Wystarczy, że skorzystasz z wiarygodnego kalkulatora rat.'
    },
    {
        question: 'Ile wynosi miesięczna rata kredytu hipotecznego?',
        answer: 'Jednym z najważniejszych parametrów kredytu hipotecznego, które powinieneś sprawdzić przy wyborze odpowiedniej dla siebie oferty, jest wysokość raty kapitałowo-odsetkowej, jaką będziesz spłacać co miesiąc. Taka rata składa się z części kapitałowej oraz odsetkowej, której wysokość jest zależna od pozostałego do spłaty kapitału kredytowego oraz wysokości oprocentowania w skali roku.' +
          '<br>' +
          'Na wartość miesięcznej raty kredytu hipotecznego wpływ ma wysokość zobowiązania, oprocentowanie, ale również system spłaty rat. Zwykle banki przy kredytach hipotecznych umożliwiają Ci wybór systemu rat malejących lub równych (stałych) w okresie zwracania środków. W przypadku rat malejących zmniejszają się one wraz z upływem czasu oraz w miarę spłaty zobowiązania. Na początku są najwyższe i potem maleją, ponieważ spłacasz coraz więcej pożyczonych od banku pieniędzy. W skład raty malejącej wchodzi niezmiennie ta sama część kapitału kredytowego oraz część odsetkowa, która jest naliczana od coraz mniejszego długu, dlatego wysokość pełnej raty się zmniejsza.' +
          '<br>' +
          'Natomiast system rat równych w okresie kredytowania polega na spłacaniu w miarę takich samych rat kapitałowo-odsetkowych, w przypadku których na początku największą ich część zajmują spłacane odsetki, a niewielką – część kapitałowa.'
    },
    {
      question: 'Jak obliczyć ratę kredytu hipotecznego?',
      answer: 'Najprostszym sposobem jest skorzystanie z kalkulatora rat kredytu hipotecznego. Wystarczy wpisać: wartość nieruchomości, wkład własny, wysokość kredytu, okres spłaty, rodzaj rat oraz typ oprocentowania. Po chwili kalkulator kredytowy przeliczy i uzyskamy szacunkowe dane. ' +
          '<br>' +
          'Posiadacze kredytów hipotecznych, mogą obliczyć orientacyjną wysokość przyszłych rat na stronie UOKiK. W kalkulatorze zmiany oprocentowania kredytu hipotecznego należy wpisać: kwotę pozostałą do spłaty, aktualne oprocentowanie albo wysokość bieżącej raty oraz datę spłaty ostatniej raty kredytu hipotecznego. Algorytm wyliczy, jak zmieni się orientacyjnie wysokość rat, przy zmianie stóp procentowych.' +
          '<br>' +
          'Oczywiście istnieją też wzory matematyczne, za pomocą których możesz sam obliczyć ratę kredytu hipotecznego. ' +
          '<br>' +
          'Wzór na ratę stałą: R=A*(1+(b/m)^n)*(1+(b/m)-1)/[(1+(b/m)^n)-1]' +
          '<br>' +
          'Symbole wskazują:' +
          '<br>' +
          'R – wysokość raty,' +
          '<br>' +
          'A – kwotę udzielonego kredytu,' +
          '<br>' +
          'b – oprocentowanie kredytu w skali roku,' +
          '<br>' +
          'm – liczbę miesięcy w roku,' +
          '<br>' +
          'n – liczbę rat.' +
          '<br>' +
          '^ – znak potęgowania.' +
          '<br>' +
          'Wysokość raty zmiennej to suma:' +
          '<br>' +
          '• raty kapitałowej, którą liczymy wg wzoru: Rk=A/n;' +
          '<br>' +
          '• części odsetkowej, wyliczonej ze wzoru: Ro=[(A-x*Rk)*b]/12. Znak x to liczba rat pozostałych do spłaty, a b – to oprocentowanie kredytu w skali roku.' +
          '<br>' +
          'Jak widzisz, nie są to proste obliczenia, dlatego znacznie szybciej sprawdzisz wysokość rat w kalkulatorze kredytowym.'
    },
    {
      question: 'Czy kredyt hipoteczny w czasach, gdy inflacja jest dużo wyższa niż oprocentowanie, to dobra decyzja?',
      answer: 'Jeśli kredyt hipoteczny jest oprocentowany niżej niż poziom inflacji, to realny koszt kredytu będzie maleć w miarę upływu czasu. Co więcej, jeżeli Twoje wynagrodzenie rośnie w tempie zbliżonym do inflacji, albo co najmniej wyższym niż oprocentowanie kredytu, to w rezultacie będziesz płacić względnie mniejsze raty. Zatem jeśli w czasach wysokiej inflacji stać Cię na spłacanie rat kredytu, to zaciągnięcie kredytu hipotecznego będzie dobrą decyzją.'
    },
    {
      question: 'Czym jest cross-sell w ofertach kredytów hipotecznych?',
      answer: 'W przypadku kredytów hipotecznych cross-sell oznacza oferowanie klientowi dodatkowych produktów, np. dodatkowych ubezpieczeń lub konta bankowego, które w połączeniu z kredytem hipotecznym mogą obniżyć koszt kredytu. Taki cross-sell służy zwiększeniu zysku banku, ale przy odpowiednim doborze produktów może być korzystny dla kredytobiorcy.'
    },
  ];
  return guideItems;
}
