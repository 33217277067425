export function useMonthlyInstallmentExample() {
  const monthlyInstallmentItems = [
    {
      name: 'kredyt hipoteczny 50 tys. zł - jaka rata',
      years_10: '553,85 zł',
      years_15: '420,58 zł',
      years_20: '356,77 zł',
      years_25: '320,62 zł',
      years_30: '298,17 zł',
      years_35: '283,42 zł',
    },
    {
      name: 'kredyt hipoteczny 100 tys. zł - jaka rata',
      years_10: '1 107,70 zł',
      years_15: '841,16 zł',
      years_20: '713,55 zł',
      years_25: '641,25 zł',
      years_30: '596,34 zł',
      years_35: '566,84 zł',
    },
    {
      name: 'kredyt hipoteczny 150 tys. zł - jaka rata',
      years_10: '1 661,54 zł',
      years_15: '1 261,74 zł',
      years_20: '1 070,32 zł',
      years_25: '961,87 zł',
      years_30: '894,51 zł',
      years_35: '850,25 zł',
    },
    {
      name: 'kredyt hipoteczny 200 tys. zł - jaka rata',
      years_10: '2 215,39 zł',
      years_15: '1 682,32 zł',
      years_20: '1 427,10 zł',
      years_25: '1 282,50 zł',
      years_30: '1 192,68 zł',
      years_35: '1 133,67 zł',
    },
    {
      name: 'kredyt hipoteczny 300 tys. zł - jaka rata',
      years_10: '3 323,09 zł',
      years_15: '2 523,47 zł',
      years_20: '2 140,65 zł',
      years_25: '1 923,75 zł',
      years_30: '1 789,02 zł',
      years_35: '1 700,51 zł',
    }, {
      name: 'kredyt hipoteczny 400 tys. zł - jaka rata',
      years_10: '4 430,78 zł',
      years_15: '3 364,63 zł',
      years_20: '2 854,20 zł',
      years_25: '2 564,99 zł',
      years_30: '2 385,36 zł',
      years_35: '2 267,34 zł',
    }, {
      name: 'kredyt hipoteczny 500 tys. zł - jaka rata',
      years_10: '5 538,48 zł',
      years_15: '4 205,79 zł',
      years_20: '3 567,75 zł',
      years_25: '3 206,24 zł',
      years_30: '2 981,70 zł',
      years_35: '2 834,18 zł',
    }, {
      name: 'kredyt hipoteczny 700 tys. zł - jaka rata',
      years_10: '7 753,87 zł',
      years_15: '5 888,11 zł',
      years_20: '4 994,85 zł',
      years_25: '4 488,74 zł',
      years_30: '4 174,38 zł',
      years_35: '3 967,85 zł',
    },
    {
      name: 'kredyt hipoteczny 1 000 000 zł - jaka rata',
      years_10: '11 076,96 zł',
      years_15: '8 411,58 zł',
      years_20: '7 135,50 zł',
      years_25: '6 412,48 zł',
      years_30: '5 963,40 zł',
      years_35: '5 668,36 zł',
    },
  ];
  type MonthlyInstallmentKeys = 'years_10' | 'years_15' | 'years_20' | 'years_25' | 'years_30' | 'years_35' | 'name'
  const monthlyInstallmentHeaders: {text: string, value: keyof Record<MonthlyInstallmentKeys, string>}[] = [
    {
      text: '',
      value: 'name',
    },
    { text: '10 lat', value: 'years_10', },
    { text: '15 lat', value: 'years_15', },
    { text: '20 lat', value: 'years_20', },
    { text: '25 lat', value: 'years_25', },
    { text: '30 lat', value: 'years_30', },
    { text: '35 lat', value: 'years_35', },
  ];
  return {
    monthlyInstallmentHeaders,
    monthlyInstallmentItems,
  };
}
